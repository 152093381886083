<template>
  <div class="viewPlan">
    <h3 v-if="types">视光处方方案</h3>
    <h4 v-if="types">最终处理方案：</h4>
    <ul class="zzfa" >
      <li v-if="zzfaList[0] == 1" :class="fnidx == 0?'xz':''" @click="teb(0)">框架配镜</li>
      <li v-if="zzfaList[1] == 1" :class="fnidx == 1?'xz':''" @click="teb(1)">预防管理</li>
      <li v-if="zzfaList[2] == 1" :class="fnidx == 2?'xz':''" @click="teb(2)">视觉训练</li>
      <li v-if="zzfaList[3] == 1" :class="fnidx == 3?'xz':''" @click="teb(3)">角膜接触镜</li>
      <li v-if="zzfaList[4] == 1" :class="fnidx == 4?'xz':''" @click="teb(4)">斜弱视治疗</li>
      <li v-if="zzfaList[5] == 1" :class="fnidx == 5?'xz':''" @click="teb(5)">屈光手术</li>
    </ul>
    <h4  v-if="(types || title) && zzclfa">{{title}}方案</h4>
    <div class="fanr">
      <div class="kkpj" v-if="fnidx == 0">
        <div style="height: 10vh;overflow-y: auto;" v-if="zzclfa">
          <p style="margin-bottom: 10px;" v-for="items in kjList">{{items}}</p>
        </div>

        <div style="display: flex;align-items: center;">
          <div style="padding-right: 1vw">佩戴方式</div>
          <el-select v-model="otherData.S_OP_Json.Scene_WearingMethod" placeholder="请选择">
            <el-option
                v-for="item in pdfs"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="yfgl">

      </div>
      <div class="sjxl" v-if="fnidx == 2">
        <p></p>
        <el-button @click="$router.push({path: '/training/trainingPlan'})">查看方案详情</el-button>
      </div>
      <div class="jmxl" v-if="fnidx == 3">
        <div class="jmxl-a">
          <p>SCL</p>
          <div>
            <div class="pd-10">
              <el-radio-group v-model="SCLOD">
                <el-radio class="w-30" :label="0">常规片</el-radio>
                <el-radio class="w-30" :label="1">散光片</el-radio>
                <el-radio class="w-30" :label="2">多焦点</el-radio>
                <el-radio class="w-30" :label="3">美瞳片</el-radio>
                <el-radio class="w-30" :label="4">医用美容片</el-radio>
              </el-radio-group>
            </div>
            <div class="pd-10">
              <el-radio-group v-model="SCLOS">
                <el-radio class="w-30" :label="0">日抛型</el-radio>
                <el-radio class="w-30" :label="1">半月抛</el-radio>
                <el-radio class="w-30" :label="2">月抛型</el-radio>
                <el-radio class="w-30" :label="3">半年抛</el-radio>
                <el-radio class="w-30" :label="4">带确定</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="jmxl-a pd-10">
          <p>OK镜</p>
          <div>
            <el-radio-group v-model="OK">
              <el-radio class="w-30" :label="0">常规VST</el-radio>
              <el-radio class="w-30" :label="1">散光VST</el-radio>
              <el-radio class="w-30" :label="2">常规CRT</el-radio>
              <el-radio class="w-30" :label="3">散光CRT</el-radio>
              <el-radio class="w-30" :label="4">待确定</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="jmxl-a">
          <p>RGP</p>
          <div>
            <el-radio-group v-model="RGP">
              <el-radio class="w-30" :label="0">超多弧区</el-radio>
              <el-radio class="w-30" :label="1">双非球面</el-radio>
              <el-radio class="w-30" :label="2">后复曲面</el-radio>
              <el-radio class="w-30" :label="3">双复曲面</el-radio>
              <el-radio class="w-30" :label="4">圆锥角膜RGP</el-radio>
              <el-radio class="w-30" :label="5">待确定</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="xrs" v-if="fnidx == 4">
        <el-checkbox-group v-model="crs">
          <el-checkbox :label="0">配镜</el-checkbox>
          <el-checkbox :label="1">双眼视功能训练</el-checkbox>
          <el-checkbox :label="2">串珠</el-checkbox>
          <el-checkbox :label="3" >同视机</el-checkbox>
          <el-checkbox :label="4" >手术</el-checkbox>
          <el-checkbox :label="5" >治疗用眼帖眼罩</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <div class="hx"></div>
    <div class="clfa" v-if="types">
      <h4>处方方案数据</h4>
      <el-select v-model="cfValue" class="sr" placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-select v-if="cfValue == 1" v-model="cftype" class="sr" placeholder="" >
        <el-option
            v-for="item in falx"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="addCf" type="primary">添加</el-button>
    </div>

    <div v-for="(item, idx) in cfarr">

      <div v-if="item.S_OP_Json.S_OP_IO_Type == 0">
        <div class="top" >
          <div class="titles">处方{{idx+1}}</div>
          <div class="cz">
            <el-button v-if="types" type="danger" @click="deletes(idx)">删除</el-button>
          </div>
        </div>
        <h4 style="padding-top: .2rem">框架远用</h4>
        <div class="srA">
          <div>
            <ul class="content-sr mar-20">
              <li></li>
              <li>球镜</li>
              <li>柱镜</li>
              <li>轴向</li>
              <li>远用矫正视力</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DSOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DSOD, 'S_OP_IO_DSOD', idx, true)"  @change="addj(item.S_OP_Json.S_OP_IO_DSOD, item,'S_OP_IO_DSOD')" v-model="item.S_OP_Json.S_OP_IO_DSOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DCOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DCOD, 'S_OP_IO_DCOD', idx)" v-model="item.S_OP_Json.S_OP_IO_DCOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_XOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_XOD, 'S_OP_IO_XOD', idx)" v-model="item.S_OP_Json.S_OP_IO_XOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DVAOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DVAOD, 'S_OP_IO_DVAOD', idx)" v-model="item.S_OP_Json.S_OP_IO_DVAOD" :disabled="!types" placeholder=""></el-input></li>
            </ul>
            <ul class="content-sr mar-20">
              <li>OS</li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DSOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DSOS, 'S_OP_IO_DSOS', idx, true)" @change="addj(item.S_OP_Json.S_OP_IO_DSOS, item,'S_OP_IO_DSOS')" v-model="item.S_OP_Json.S_OP_IO_DSOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DCOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DCOS, 'S_OP_IO_DCOS', idx)" v-model="item.S_OP_Json.S_OP_IO_DCOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_XOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_XOS, 'S_OP_IO_XOS', idx)" v-model="item.S_OP_Json.S_OP_IO_XOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DVAOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DVAOS, 'S_OP_IO_DVAOS', idx)" v-model="item.S_OP_Json.S_OP_IO_DVAOS" :disabled="!types" placeholder=""></el-input></li>
            </ul>
          </div>
          <el-button class="last" icon="el-icon-refresh" @click="zhcf(idx)" type="primary">转换处方</el-button>
        </div>

        <div class="srA">
          <div>
            <ul class="content-sr mar-20">
              <li></li>
              <li>近用矫正视力</li>
              <li>瞳距</li>
              <li>瞳高</li>
              <li>ADD</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li><el-input  @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_NVAOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_NVAOD, 'S_OP_IO_NVAOD', idx)" v-model="item.S_OP_Json.S_OP_IO_NVAOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input  @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_PDOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_PDOD, 'S_OP_IO_PDOD', idx)" v-model="item.S_OP_Json.S_OP_IO_PDOD" :disabled="!types" placeholder=""></el-input>mm</li>
              <li><el-input  @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_PHOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_PHOD, 'S_OP_IO_PHOD', idx)" v-model="item.S_OP_Json.S_OP_IO_PHOD" :disabled="!types" placeholder=""></el-input>mm</li>
              <li><el-input  @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_ADDOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_ADDOD, 'S_OP_IO_ADDOD', idx)" v-model="item.S_OP_Json.S_OP_IO_ADDOD" :disabled="!types" placeholder=""></el-input></li>
            </ul>
            <ul class="content-sr mar-20">
              <li>OS</li>
              <li><el-input  @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_NVAOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_NVAOS, 'S_OP_IO_NVAOS', idx)" v-model="item.S_OP_Json.S_OP_IO_NVAOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input  @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_PDOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_PDOS, 'S_OP_IO_PDOS', idx)" v-model="item.S_OP_Json.S_OP_IO_PDOS" :disabled="!types" placeholder=""></el-input>mm</li>
              <li><el-input  @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_PHOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_PHOS, 'S_OP_IO_PHOS', idx)" v-model="item.S_OP_Json.S_OP_IO_PHOS" :disabled="!types"  placeholder=""></el-input>mm</li>
              <li><el-input  @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_ADDOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_ADDOS, 'S_OP_IO_ADDOS', idx)" v-model="item.S_OP_Json.S_OP_IO_ADDOS" :disabled="!types" placeholder=""></el-input></li>
            </ul>
          </div>
        </div>

        <div class="srA">
          <div>
            <ul class="content-sr mar-20">
              <li></li>
              <li>垂直棱镜</li>
              <li>垂直基底</li>
              <li>水平棱镜</li>
              <li>水平基底</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_ArriseOD_V'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_ArriseOD_V, 'S_OP_IO_ArriseOD_V', idx)" v-model="item.S_OP_Json.S_OP_IO_ArriseOD_V" :disabled="!types" placeholder=""></el-input></li>
              <li>
                <el-select v-model="item.S_OP_Json.S_OP_IO_BasisOD_V" class="sr" filterable allow-create placeholder="" :disabled="!types" >
                  <el-option
                      v-for="item in czjd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_ArriseOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_ArriseOD, 'S_OP_IO_ArriseOD', idx)" v-model="item.S_OP_Json.S_OP_IO_ArriseOD" :disabled="!types" placeholder=""></el-input></li>
              <li>
                <el-select v-model="item.S_OP_Json.S_OP_IO_BasisOD" class="sr" filterable allow-create placeholder="" :disabled="!types" >
                  <el-option
                      v-for="item in jd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
            </ul>
            <ul class="content-sr mar-20">
              <li>OS</li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_ArriseOS_V'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_ArriseOS_V, 'S_OP_IO_ArriseOS_V', idx)" v-model="item.S_OP_Json.S_OP_IO_ArriseOS_V" :disabled="!types" placeholder=""></el-input></li>
              <li>
                <el-select v-model="item.S_OP_Json.S_OP_IO_BasisOS_V" class="sr" filterable allow-create placeholder="" :disabled="!types" >
                  <el-option
                      v-for="item in czjd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_ArriseOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_ArriseOS, 'S_OP_IO_ArriseOS', idx)" v-model="item.S_OP_Json.S_OP_IO_ArriseOS" :disabled="!types"  placeholder=""></el-input></li>
              <li>
                <el-select v-model="item.S_OP_Json.S_OP_IO_BasisOS" class="sr" filterable allow-create placeholder="" :disabled="!types" >
                  <el-option
                      v-for="item in jd"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </li>
            </ul>
          </div>
        </div>

        <div class="bottom">
          <p>方案类型</p>
          <p>{{item.S_OP_Json.S_OP_IO_FrameType}}</p>
        </div>
        <div class="bottom">
          <p>备注</p>
          <el-input
              :disabled="!types"
              class="bzs"
              type="textarea"
              placeholder=""
              v-model="item.S_OP_Json.S_OP_IO_Remark"
              :autosize="{ minRows: 2, maxRows: 2 }"
              resize="none"
              show-word-limit
          >
          </el-input>
        </div>
      </div>

      <div v-if="item.S_OP_Json.S_OP_IO_Type == 2">
        <div class="top" >
          <div class="titles">处方{{idx+1}}</div>
          <div class="cz">
            <el-button v-if="types" type="danger" @click="deletes(idx)">删除</el-button>
          </div>
        </div>
        <h4 style="padding-top: .2rem">框架近用</h4>
        <div class="srA">
          <div>
            <ul class="content-sr mar-20">
              <li></li>
              <li>球镜</li>
              <li>柱镜</li>
              <li>轴向</li>
              <li>近用视力矫正</li>
              <li>瞳距</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DSOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DSOD, 'S_OP_IO_DSOD', idx, true)" @change="addj(item.S_OP_Json.S_OP_IO_DSOD, item, 'S_OP_IO_DSOD')" v-model="item.S_OP_Json.S_OP_IO_DSOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DCOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DCOD, 'S_OP_IO_DCOD', idx)" v-model="item.S_OP_Json.S_OP_IO_DCOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_XOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_XOD, 'S_OP_IO_XOD', idx)" v-model="item.S_OP_Json.S_OP_IO_XOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_NVAOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_NVAOD, 'S_OP_IO_NVAOD', idx)" v-model="item.S_OP_Json.S_OP_IO_NVAOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_PDOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_PDOD, 'S_OP_IO_PDOD', idx)" v-model="item.S_OP_Json.S_OP_IO_PDOD" :disabled="!types" placeholder=""></el-input>mm</li>
            </ul>
            <ul class="content-sr mar-20">
              <li>OS</li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DSOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DSOS, 'S_OP_IO_DSOS', idx, true)" @change="addj(item.S_OP_Json.S_OP_IO_DSOS, item, 'S_OP_IO_DSOS')" v-model="item.S_OP_Json.S_OP_IO_DSOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DCOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DCOS, 'S_OP_IO_DCOS', idx)" v-model="item.S_OP_Json.S_OP_IO_DCOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_XOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_XOS, 'S_OP_IO_XOS', idx)" v-model="item.S_OP_Json.S_OP_IO_XOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_NVAOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_NVAOS, 'S_OP_IO_NVAOS', idx)" v-model="item.S_OP_Json.S_OP_IO_NVAOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_PDOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_PDOS, 'S_OP_IO_PDOS', idx)" v-model="item.S_OP_Json.S_OP_IO_PDOS" :disabled="!types" placeholder=""></el-input>mm</li>
            </ul>
          </div>
          <el-button class="last" icon="el-icon-refresh" type="primary">转换处方</el-button>
        </div>
        <div class="bottom" v-if="0">
          <p>方案类型</p>
          <el-select v-model="item.S_OP_Json.S_OP_IO_FrameType" class="sr" placeholder="" :disabled="!types" >
            <el-option
                v-for="item in falx"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="bottom">
          <p>备注</p>
          <el-input
              :disabled="!types"
              class="bzs"
              type="textarea"
              placeholder=""
              v-model="item.S_OP_Json.S_OP_IO_Remark"
              :autosize="{ minRows: 2, maxRows: 2 }"
              resize="none"
              show-word-limit
          >
          </el-input>
        </div>
      </div>

      <div v-if="item.S_OP_Json.S_OP_IO_Type == 7">
        <div class="top">
          <div class="titles">处方{{idx+1}}</div>
          <div class="cz">
            <el-button v-if="types" type="danger" @click="deletes(idx)">删除</el-button>
          </div>
        </div>
        <h4 style="padding-top: .2rem">角膜接触镜</h4>
        <div class="srA">
          <div>
            <ul class="content-sr mar-20">
              <li></li>
              <li>球镜</li>
              <li>柱镜</li>
              <li>轴向</li>
              <li>视力</li>
              <li>基弧</li>
              <li>镜片直径</li>
            </ul>
            <ul class="content-sr">
              <li>OD</li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DSOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DSOD, 'S_OP_IO_DSOD', idx, 1)" @change="addj(item.S_OP_Json.S_OP_IO_DSOD, item,'S_OP_IO_DSOD')" v-model="item.S_OP_Json.S_OP_IO_DSOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DCOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DCOD, 'S_OP_IO_DCOD', idx)" v-model="item.S_OP_Json.S_OP_IO_DCOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_XOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_XOD, 'S_OP_IO_XOD', idx)" v-model="item.S_OP_Json.S_OP_IO_XOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_VAOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_VAOD, 'S_OP_IO_VAOD', idx)" v-model="item.S_OP_Json.S_OP_IO_VAOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_BCOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_BCOD, 'S_OP_IO_BCOD', idx)" v-model="item.S_OP_Json.S_OP_IO_BCOD" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DIMOD'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DIMOD, 'S_OP_IO_DIMOD', idx)" v-model="item.S_OP_Json.S_OP_IO_DIMOD" :disabled="!types" placeholder=""></el-input></li>
            </ul>
            <ul class="content-sr mar-20">
              <li>OS</li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DSOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DSOS, 'S_OP_IO_DSOS', idx, 1)" @change="addj(item.S_OP_Json.S_OP_IO_DSOS, item,'S_OP_IO_DSOS')"  v-model="item.S_OP_Json.S_OP_IO_DSOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DCOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DCOS, 'S_OP_IO_DCOS', idx)" v-model="item.S_OP_Json.S_OP_IO_DCOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_XOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_XOS, 'S_OP_IO_XOS', idx)" v-model="item.S_OP_Json.S_OP_IO_XOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_VAOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_VAOS, 'S_OP_IO_VAOS', idx)" v-model="item.S_OP_Json.S_OP_IO_VAOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_BCOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_BCOS, 'S_OP_IO_BCOS', idx)" v-model="item.S_OP_Json.S_OP_IO_BCOS" :disabled="!types" placeholder=""></el-input></li>
              <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_IO_DIMOS'&&idx===keyidx?'xz':'']" @focus="getgb(item.S_OP_Json.S_OP_IO_DIMOS, 'S_OP_IO_DIMOS', idx)" v-model="item.S_OP_Json.S_OP_IO_DIMOS" :disabled="!types" placeholder=""></el-input></li>
            </ul>
          </div>
          <el-button class="last" icon="el-icon-refresh" type="primary">转换处方</el-button>
        </div>
        <div class="bottom" v-if="0">
          <p>方案类型</p>
          <el-select v-model="item.S_OP_Json.S_OP_IO_FrameType" class="sr" placeholder="" :disabled="!types">
            <el-option
                v-for="item in cfsfalx"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="bottom">
          <p>备注</p>
          <el-input
              :disabled="!types"
              class="bzs"
              type="textarea"
              placeholder="请输入内容"
              v-model="item.S_OP_Json.S_OP_IO_Remark"
              :autosize="{ minRows: 2, maxRows: 2 }"
              resize="none"
              show-word-limit
          >
          </el-input>
        </div>
      </div>
    </div>
    <div style="display: flex;align-items: center;margin-top: 20px;margin-bottom: 30px">
      <p style="padding-right: 1vw;">复查时间</p>
      <el-date-picker
          v-model="otherData.S_OP_Json.RecheckTime"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期">
      </el-date-picker>
    </div>
    <UPfile :type="'IN'"></UPfile>
    <el-button type="primary" @click="saves" style="margin-top: 30px;">保存</el-button>
    <el-button type="primary" v-if="ists" @click="his">提交到HIS</el-button>
    <el-button type="primary" @click="sgsqm">视光师签名</el-button>

    <KEYHome v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYHome>
  </div>

</template>

<script>
export default {
  name: "viewPlan",
  data() {
    return {
      title: '',
      value1: '',
      value: '',
      fnidx: 0,
      SCLOD: '',
      SCLOS: '',
      OK: '',
      RGP: '',
      crs: [],
      cfValue: '',
      ists: false,
      pdfs: [
        {
          value: '一直佩戴',
          label: '一直佩戴'
        },
        {
          value: '需要时戴',
          label: '需要时戴'
        },
        {
          value: '看近时戴',
          label: '看近时戴'
        },
        {
          value: '交替佩戴',
          label: '交替佩戴'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      options: [
        {
          value: '1',
          label: '框架远用'
        },
        {
          value: '2',
          label: '框架近用'
        },
        {
          value: '3',
          label: '角膜接触镜'
        }
      ],
      czjd: [
        {
          value: '上',
          label: '上'
        },
        {
          value: '下',
          label: '下'
        },
      ],
      jd: [
        {
          value: '内',
          label: '内'
        },
        {
          value: '外',
          label: '外'
        }
      ],
      falx: [
        {
          value: '单光',
          label: '单光'
        },
        {
          value: '渐进',
          label: '渐进'
        },
        {
          value: '抗疲劳',
          label: '抗疲劳'
        },
        {
          value: '离焦',
          label: '离焦'
        },
        {
          value: '儿童多焦点',
          label: '儿童多焦点'
        },
        {
          value: '双光镜片',
          label: '双光镜片'
        },
      ],
      cfsfalx: [
        {
          value: 'SCL',
          label: 'SCL'
        },
        {
          value: 'RGP',
          label: 'RGP'
        },
        {
          value: 'OK镜片',
          label: 'OK镜片'
        },
      ],

      postData: [],
      cfarr: [],
      kjyy: {},

      ysArr: [
        {
          S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
          S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
          S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'00000000-0000-0000-0000-000000000000',
          S_OP_ID: "00000000-0000-0000-0000-000000000000",
          S_OP_Json:{
            S_OP_IO_ADDOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_AddOD,
            S_OP_IO_ADDOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_AddOS,
            S_OP_IO_ArriseOD: "",
            S_OP_IO_ArriseOS: "",
            S_OP_IO_BCOD: "",
            S_OP_IO_BCOS: "",
            S_OP_IO_BasisOD: "",
            S_OP_IO_BasisOS: "",
            S_OP_IO_ChooseId: this.$store.state.physicianVisits.xzConsulting,
            S_OP_IO_DCOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DCOD,
            S_OP_IO_DCOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DCOS,
            S_OP_IO_DIMOD: "",
            S_OP_IO_DIMOS: "",
            S_OP_IO_DSOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DSOD,
            S_OP_IO_DSOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DSOS,
            S_OP_IO_DVAOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DVAOD,
            S_OP_IO_DVAOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DVAOS,
            S_OP_IO_FrameType: "",
            S_OP_IO_NVAOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_NVAOD,
            S_OP_IO_NVAOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_NVAOS,
            S_OP_IO_Number: "",
            S_OP_IO_PDOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_PDOD,
            S_OP_IO_PDOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_PDOS,
            S_OP_IO_PHOD: "",
            S_OP_IO_PHOS: "",
            S_OP_IO_Remark: "",
            S_OP_IO_Type: "0",
            S_OP_IO_VAOD: "",
            S_OP_IO_VAOS: "",
            S_OP_IO_XOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_XOD,
            S_OP_IO_XOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_XOS,
            S_OP_IO_ArriseOD_V: '',
            S_OP_IO_BasisOD_V: '',
            S_OP_IO_BasisOS_V: '',
            S_OP_IO_ArriseOS_V: '',
          },
          S_OP_Model: "S_OP_IO",
          S_OP_Type: "Inspection"
        },
        {
          S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
          S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
          S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'00000000-0000-0000-0000-000000000000',
          S_OP_ID: "00000000-0000-0000-0000-000000000000",
          S_OP_Json:{
            S_OP_IO_ADDOD: '',
            S_OP_IO_ADDOS: '',
            S_OP_IO_ArriseOD: "",
            S_OP_IO_ArriseOS: "",
            S_OP_IO_BCOD: "",
            S_OP_IO_BCOS: "",
            S_OP_IO_BasisOD: "",
            S_OP_IO_BasisOS: "",
            S_OP_IO_ChooseId: this.$store.state.users.employees.ExpertId,
            S_OP_IO_DCOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DCOD,
            S_OP_IO_DCOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DCOS,
            S_OP_IO_DIMOD: "",
            S_OP_IO_DIMOS: "",
            S_OP_IO_DSOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DSOD,
            S_OP_IO_DSOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DSOS,
            S_OP_IO_DVAOD: "",
            S_OP_IO_DVAOS: "",
            S_OP_IO_FrameType: "",
            S_OP_IO_NVAOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_NVAOD,
            S_OP_IO_NVAOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_NVAOS,
            S_OP_IO_Number: "",
            S_OP_IO_PDOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_PDOD,
            S_OP_IO_PDOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_PDOS,
            S_OP_IO_PHOD: "",
            S_OP_IO_PHOS: "",
            S_OP_IO_Remark: "",
            S_OP_IO_Type: "2",
            S_OP_IO_VAOD: "",
            S_OP_IO_VAOS: "",
            S_OP_IO_XOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_XOD,
            S_OP_IO_XOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_XOS
          },
          S_OP_Model: "S_OP_IO",
          S_OP_Type: "Inspection"
        },
        {
          S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
          S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
          S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'00000000-0000-0000-0000-000000000000',
          S_OP_ID: "00000000-0000-0000-0000-000000000000",
          S_OP_Json:{
            S_OP_IO_ADDOD: "",
            S_OP_IO_ADDOS: "",
            S_OP_IO_ArriseOD: "",
            S_OP_IO_ArriseOS: "",
            S_OP_IO_BCOD: "",
            S_OP_IO_BCOS: "",
            S_OP_IO_BasisOD: "",
            S_OP_IO_BasisOS: "",
            S_OP_IO_ChooseId: this.$store.state.physicianVisits.xzConsulting,
            S_OP_IO_DCOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DCOD,
            S_OP_IO_DCOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DCOS,
            S_OP_IO_DIMOD: "",
            S_OP_IO_DIMOS: "",
            S_OP_IO_DSOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DSOD,
            S_OP_IO_DSOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_DSOS,
            S_OP_IO_DVAOD: "",
            S_OP_IO_DVAOS: "",
            S_OP_IO_FrameType: "",
            S_OP_IO_NVAOD: "",
            S_OP_IO_NVAOS: "",
            S_OP_IO_Number: "",
            S_OP_IO_PDOD: "",
            S_OP_IO_PDOS: "",
            S_OP_IO_PHOD: "",
            S_OP_IO_PHOS: "",
            S_OP_IO_Remark: "",
            S_OP_IO_Type: "7",
            S_OP_IO_VAOD: "",
            S_OP_IO_VAOS: "",
            S_OP_IO_XOD: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_XOD,
            S_OP_IO_XOS: this.$store.state.eyeTest.refractiveData.S_OP_Json.S_OP_R_SS_XOS
          },
          S_OP_Model: "S_OP_IO",
          S_OP_Type: "Inspection"
        }
      ],

      zzfaList: [],
      zzclfa: false,

      otherData: {
        S_OP_Json: {}
      },

      kjList: [],
      cftype: '',


      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      keyidx: 0
    }
  },
  props: {
    types: {
      default: 1 //0代表从框架定配过来
    }
  },
  watch: {
    cfarr: {
      handler(n, o) {
        this.$store.commit('expertClinic/upViewPlan', n)
      },
      deep: true
    },
    'otherData': {
      handler(n, o) {
        this.$store.commit('expertClinic/upExpertClinicData', n)
      },
      deep: true,
      immediate: true
    },
    crs: {
      handler(n, o) {
        let arr = [0,0,0,0,0,0]
        for (let i = 0; i < n.length; i++) {
          arr[n[i]] = 1
        }
        this.otherData.S_OP_Json.ObliqueAmblyopia_Solution = arr.join(',')
      },
      deep: true,
      immediate: true
    },
    SCLOD: function (n, o) {
      this.otherData.S_OP_Json.CL_Solution_SCL = this.cldarr(n, 5)
    },
    SCLOS: function (n, o) {
      this.otherData.S_OP_Json.CL_Solution_Frequency = this.cldarr(n, 5)
    },
    OK: function (n, o) {
      this.otherData.S_OP_Json.CL_Solution_OKLens = this.cldarr(n, 5)
    },
    RGP: function (n, o) {
      this.otherData.S_OP_Json.CL_Solution_RGP = this.cldarr(n, 6)
    },
    "$store.state.expertClinic.isupData": function () {
      this.otherData = this.$store.state.expertClinic.expertClinicData
      this.crs = this.clarr(this.otherData.S_OP_Json.ObliqueAmblyopia_Solution)
      this.SCLOD = this.clarr(this.otherData.S_OP_Json.CL_Solution_SCL, 1)
      this.SCLOS = this.clarr(this.otherData.S_OP_Json.CL_Solution_Frequency, 1)
      this.OK = this.clarr(this.otherData.S_OP_Json.CL_Solution_OKLens, 1)
      this.RGP = this.clarr(this.otherData.S_OP_Json.CL_Solution_RGP, 1)
      let str = this.otherData.S_OP_Json.Refractive_Solution
      this.zzfaList = str.split(',')
      this.zzclfa = this.zzfaList.some(item => item == 1)
      this.fnidx = this.zzfaList.indexOf('1')!=-1?this.zzfaList.indexOf('1'):0
      let arr = this.otherData.S_OP_Json.ListSceneRecomm,list = []
      if (arr && arr.length > 0) {
         for (let i = 0; i < arr.length; i++) {
           list.push(arr[i].RecommKey.strRecommLensDes)
         }
      }
      this.kjList = list
    }
  },
  created() {
    if (this.$store.state.users.user.Company.CompanyId == 'ae91f570-73b4-4dc2-90a9-0b698c05cac3') {
      this.ists = true
    }
    document.addEventListener('click', this.getXY, false)
    if (!this.$store.state.users.employees) {
      return false
    }
    if (!this.$store.state.users.CsUser && !this.$store.state.physicianVisits.xzConsulting) { return }
    this._api.expertClinic.getViewPlan()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length > 0) {
        let arr = res.GetListResult.filter(item => item.S_OP_Model == "S_OP_IO")
        this.cfarr = arr.map(item => {
          item.S_OP_Json = this._clJson(item.S_OP_Json)
          return item
        })
        // console.log(res)
      }
    })
  },
  methods: {
    his() {

      let cs = this.$loading({
        text: "提交中..."
      })
      this._http.post(this._getUrl('SZSCHis_PostToHIS'), {
        "token": {
          "TimeSpan": 1559227408,
          "sToken": "D35C30181D26439F92471835A4A98624"
        },
        "ChooseId":this.$store.state.physicianVisits.xzConsulting,
        "ExpertId":this.$store.state.users.employees.ExpertId,
        "ExpertName": this.$store.state.users.employees.ExpertName,//加这个
      })
      .then(res => {
        cs.close()
        this.$alert(res.displaymsg, "提示")
        // console.log(res)
      })
    },

    cfData(e) {
      //console.log(e)
    },

    addj(num,obj,key) {
      let nums = ""
      if (Number(num) && Number(num) != 0) {
        // console.log(num.indexOf('+'))
        if (num.indexOf('+') < 0) {
          nums = num>0?"+"+num:num
        }else {
          nums = num
        }
      }else {
        nums = ""
        if (Number(num) == 0) {
          nums = 0
        }
      }
      this.$set(obj.S_OP_Json, key, nums)
    },

    addCf() {
      let obj = JSON.parse(JSON.stringify(this.ysArr[Number(this.cfValue)-1]))
      if (this.cfValue == 1) {
        obj.S_OP_Json.S_OP_IO_FrameType = this.cftype
      }
      this.cfarr.push(obj)
    },
    deletes(idx) {
      this.cfarr.splice(idx, 1)
    },
    clarr(str, type = 0) {
      if (!str) return ''
      let arr = str.split(',')
      let item = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == 1) {
          item.push(i)
        }
      }
      if (type) {
        return item[0]
      }else {
        return item
      }
    },
    cldarr(num, len) {
      let arr = new Int8Array(len);
      arr[num] = 1
      return arr.join(',')
    },
    teb(idx) {
      this.fnidx = idx
      switch (idx) {
        case 0:
          this.title = '框架配镜'
              break
        case 1:
          this.title = '预防管理'
          break
        case 2:
          this.title = '视觉训练'
          break
        case 3:
          this.title = '角膜接触镜'
          break
        case 4:
          this.title = '斜弱视治疗'
          break
        case 5:
          this.title = '屈光手术'
          break
      }
    },


    /*
    处方转换规则:
    球镜DS_OD    柱镜DC_OD    轴向X_OD
    球镜DS_OS    柱镜DC_OS   轴向X_OS

    点击处方转换后:
    (所有计算都带入正负号)
    1.如果DS_OD和DC_OD都不为空:
    DS_OD =  DS_OD + DC_OD

    2.如果DC_OD不为空:
    DC_OD =  -1 * DC_OD

    3.如果X_OD不为空:
     if(X_OD > 90) : X_OD = X_OD - 90
     else  :  X_OD = X_OD + 90

    4.如果DS_OS和DC_OS都不为空:
    DS_OS =  DS_OS + DC_OS

    5.如果DC_OS不为空:
    DC_OS =  -1 * DC_OS

    6.如果X_OS不为空:
     if(X_OS > 90) : X_OS = X_OS - 90
     else  :  X_OS = X_OS + 90
    * */


    zhcf(idx) {

    },
    saves() {
      this.$store.commit('isbc/upZjzs', {key: 'Recomm3', val: true})
      this._api.expertClinic.saveViewPlan()
    },
    sgsqm() {
      if (this.$store.state.isbc.zjzs.Recomm3) {
        this._api.expertClinic.sgsqm()
            .then(res => {
              // console.log(res)
              if (typeof res === 'object') {
                if (res.InsertResult) {
                  this.$store.commit('physicianVisits/upissgqm', false)
                  this.$alert('提交签名成功', '提示')
                }else {
                  this.$alert('提交签名失败', '提示')
                }
              }
            })
      }else {
        this.$alert('请先保存处方方案数据', '提示')
      }

    },




    keyd(e) {
      // e.returnValue= false
    },
    getgb(str, key, idx,iszf) {
      // console.log(idx)
      // document.activeElement.blur();
      this.showkeys = true
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
      this.keyidx = idx
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.cfarr[this.keyidx].S_OP_Json, this.srval, e)
      if (this.cfarr[this.keyidx].S_OP_Json[this.srval] && !isNaN(Number(this.cfarr[this.keyidx].S_OP_Json[this.srval])) && this.iszf) {
        if (this.cfarr[this.keyidx].S_OP_Json[this.srval] && Number(this.cfarr[this.keyidx].S_OP_Json[this.srval]) > 0 && this.cfarr[this.keyidx].S_OP_Json[this.srval].indexOf('+') < 0  && this.cfarr[this.keyidx].S_OP_Json[this.srval].indexOf('-') < 0) {
          this.$set(this.cfarr[this.keyidx].S_OP_Json, this.srval, '+'+e)
        }
      }
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">
  .viewPlan {
    width: 90%;
    margin: 0 auto;
    font-size: 14px;
    padding-bottom: 50px;
  }
  h3 {padding-top: .4rem}
  h4 {
    text-align: left;
    width: 1.5rem;
    padding: 0 .2rem;
    padding-top: .2rem;
  }
  .box {
    width: 100%;
    height: 20vh;
  }
  .hx {
    width: 100%;
    height: 5px;
    background: #bbbaba;
    margin: .15rem 0;
  }
  .clfa {
    display: flex;
    align-items: center;
    justify-content: start;
    .sr {
      margin: 0 .3rem;
    }
  }
  .mar-20{margin-top: 20px}
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    background: #efefef;
    padding: 10px 0;
    border-radius: 5px;
    .titles {
      padding-left: 40px;
    }
    .cz {padding-right: 40px}
  }
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 3em;
        //padding-right: .rem;
        justify-content: start;
        //margin-right: 5px;
      }
    }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-top: 32px;
    }
  }
  .meed {
    ul{
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
      }
      li:nth-child(1) {
        width: 4vw;
        justify-content: start;
        margin-right: 0.05rem;
      }
    }
    .contentu {
      display: flex;
      justify-content: start;
      align-items: center;
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
      }
      .box {width: 8vw;}
      .left {
        margin-right: 0.4rem;
        .lefta {
          display: flex;
          align-items: center;
          p {width: 4vw;text-align: left;}
        }
      }
      .rights {
        display: flex;
        align-items: center;
        justify-content: start;
        .padd-40 {padding-right: 0.4rem;}
      }
    }
  }
  .bottom {
    margin: 0 auto;
    display: flex;
    justify-content: start;
    margin-top: 40px;
    .bzs {
      width: 95%;
    }
    p {width: 4em;text-align: left; padding-right: .2rem}
  }
  .zzfa {
    display: flex;
    justify-content: start;
    background: #e8e8e8;
    margin: 10px 0;
    padding: 5px 5px;
    border-radius: 5px;
    li {
      width: 10vw;
      padding: 2px 0;
      cursor: pointer;
      //border-right: 1px solid #cdcccc;
    }
    .xz {
      background: #FFFFFF;
      border: none;
      border-radius: 5px;
    }
  }
  .fanr {
    height: 15vh;
    padding-left: 30px;
    padding-top: 10px;
    overflow-y: auto;
    text-align: left;
    .kkpj {
      p {
        background: #e8e8e8;
        padding:5px 10px;
      }
    }
  }
  .jmxl {
    .jmxl-a {
      display: flex;
      align-items: start;
      justify-content: start;
    }
    .pd-10 {padding-bottom: 10px;}
    p {padding-right: 20px;width: 3em;}
    .w-30 {width: 120px}
  }
  ::v-deep .xz input {background: #e8e6e6}
</style>
